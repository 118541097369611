.nav-menu {
  padding: 10px 20px;
  border-left: 1px solid #e6e6e6;
  cursor: pointer;
}

.nav-menu:hover {
  background-color: #f7f9f9;
}

.nav-dropdown {
  position: absolute;
  background-color: #fff;
  transform: scale(0.95) translateY(-20px);
  background-color: #fff;
  border-radius: 0 0 3px 3px;
  border-top: 0;
  padding: 0;
  right: 8px;
  top: 57px;
  width: 248px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.1s ease-in-out 0s, visibility 0s linear 0.2s,
    z-index 0s linear 0s;
  transition-delay: 0s, 0s, 0.2s;
  z-index: 5;
  list-style: none;
  overflow: hidden;
}

.nav-dropdown ul {
  padding: 0px;
  margin: 0px;
}

.nav-dropdown li {
  display: list-item;
  text-align: -webkit-match-parent;
  display: block;
  color: #626262;
  width: 248px;
  padding: 10px 20px;
  text-align: left;
  border: 0;
  border-top: 1px solid #e6e6e6;
  text-decoration: none !important;
  transition: all ease 0.2s;
  cursor: pointer;
}

.nav-dropdown li:hover {
  background-color: #f7f9f9;
}

.circle {
  width: 28px;
  height: 28px;
  background: #fff;
  padding: 5px;
  margin-right: 8px;
  border-radius: 50px;
}

svg {
  position: relative;
  top: 4px;
}
.Accounts h2 {
  font-weight: 300;
  font-size: 21px;
  padding: 15px 25px;
}

.Accounts h3 {
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 0px;
  font-size: 14px !important;
}

.accountsColumn {
  position: relative;
}

.accountsOverlay {
  background: #fff;
  position: absolute;
  top: -5px;
  width: 100%;
  right: 5px;
  z-index: 999;
}

.accountsOverlay h3 {
  padding: 5px;
}

.accountsOverlay h3:hover {
  background: #4800d8;
  color: #fff;
}

.blue {
  color: #4800d8;
}

.Accounts h4 {
  font-size: 14px;
  font-weight: 100;
  margin: 15px 0px;
  opacity: 0.8;
  letter-spacing: 0.5px;
}

.accounts-header h4 {
  text-align: center;
}

.Accounts h4 svg {
  margin-right: 5px;
  z-index: 0 !important;
}

.Accounts h4:hover {
  color: #000;
  cursor: pointer;
  opacity: 1;
}

.Accounts h4.active {
  color: #4800d8;
  opacity: 1;
  font-weight: 700;
}

.active-header {
  color: #4800d8;
  font-weight: 700 !important;
}

.accounts-header {
  padding: 0px 15px;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}

.accounts-body {
  padding: 20px;
}

.active-header h4 {
  height: 30px;
}

.accounts-header {
  background: #fff;
}

.accounts-footer {
  position: absolute;
  bottom: 0px;
  padding-top: 50px;
}

.cardComponent {
  background-color: #fff;
  width: 100%;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  min-height: 600px;
  overflow-x: hidden;
  padding: 15px;
}

.cardComponent .home h3 {
  font-size: 20px;
  padding: 20px;
  font-weight: 400;
}

.cardComponent .home .step p {
  color: #4800d8;
}

.cardComponent .home .step {
  padding: 20px;
  margin: 0px;
  cursor: pointer;
}
.cardComponent .home .step:hover {
  background-color: #f8fcff;
  transition: 0.5s all ease;
}

.referLink {
  background-color: #e3e8ee;
  padding: 10px;
}

.step h4 {
  margin: 0;
  padding: 15px 0px;
}

.Accounts small {
  font-weight: 100 !important;
  font-size: 15px;
}
